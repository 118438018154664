<!-- eslint-disable -->
<template>
  <div class="es-gift-cards-container">
    <div class="container margin_30_40">
      <div class="main_title center">
        <h2>{{ $t('views.giftcards.title') }}</h2>
        <p>{{ $t('views.giftcards.title_description') }}</p>
      </div>
      <div class="row justify-content-center">
        <div class="tab-content pricing-tab-content" id="pricing-tab-content">
          <div
            v-for="category in getCategories"
            :key="category.id"
            role="tabpanel"
            :class="`tab-pane ${selectedType === category.id ? 'active': ''}`"
            :id="category.id"
            :aria-labelledby="`${category.id}-tab`"
          >
            <div class="row">
              <div
                v-for="card in category.gift_card_templates"
                :key="card.id"
                class="col-md-3 my-4 transition-3d-hover"
                @click.prevent="onClick(card)"
              >
                <img :src="card.absolute_image_url" width="100%" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';
  import { mapActions, mapGetters } from 'vuex';
  import { GiftCard } from '@/interfaces/GiftCards';

  export default Vue.extend({
    name: 'es-gift-cards',

    /* eslint-disable */
    metaInfo: {
      title: 'Cardul cadou Ease - Cumpara si trimite instant o doza de relaxare',
      titleTemplate: '',
      description: 'Daruieste un card cadou Ease pentru a-ti arata aprecierea fata de cei dragi sau pentru a-ti rasplati clientii sau membrii din echipa ta.',
      meta: [
        { name: 'description', content: 'Daruieste un card cadou Ease pentru a-ti arata aprecierea fata de cei dragi sau pentru a-ti rasplati clientii sau membrii din echipa ta.' },
        { name: 'og:title', content: 'Cardul cadou Ease - Cumpara si trimite instant o doza de relaxare' },
        { name: 'og:description', content: 'Daruieste un card cadou Ease pentru a-ti arata aprecierea fata de cei dragi sau pentru a-ti rasplati clientii sau membrii din echipa ta.' },
        { name: 'og:image', content: require('@/assets/png/gift-card-thumb.png'), },
      ],
    },

    data: () => ({
      selectedType: 1,
    }),
    computed: {
      ...mapGetters({
        getGiftCards: 'giftCards/getGiftCards',
      }),
      getCategories() {
        return this.getGiftCards;
      },
    },

    created() {
      this.fetchGiftCards();
    },

    methods: {
      ...mapActions({
        setGiftCard: 'giftCards/setGiftCard',
        fetchGiftCards: 'giftCards/fetchGiftCards',
      }),
      onClick(card) {
        this.setGiftCard(card);
        const route = `/carduri-cadou/${card.id}`;
        this.$router.push(route);
      },
    },
  });
</script>

<style type="text/css">
  .es-gift-cards-container img {
    cursor: pointer;
  }
</style>
